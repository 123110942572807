<template>
    <div class="card">
        <div class="card-header pb-0">
            <h4>{{curso.nombre }}</h4>
            <Preloader :visible="cargando" />
            <button class="btn btn-outline-primary ml-auto  btn-sm" @click="$router.go(-1)"> Regresar al curso</button>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-4">
                <table class="table mb-0 table-bordered table-hover" ref="itemtabla">
                    <thead>
                        <tr class="text-uppercase">
                            <th class="text-center px-3 py-2">ID</th>
                            <th class="text-center px-3 py-2">nombre</th>
                            <th class="text-center px-3 py-2">descripcion</th>
                            <th class="text-center px-3 py-2">Fecha de inicio</th>
                            <th class="text-center px-3 py-2">Fecha de fin</th>
                            <th class="text-center px-3 py-2">Opciones</th>
                        </tr>
                    </thead>
                    <!-- Cuerpo lista de modulos -->
                    <tbody>
                    <tr v-for="(modulo, index) in modulos" :key="index">
                        <td class="text-center" >{{ index+1 }}</td>
                        <td class="text-start ">{{ modulo.nombre }}</td>
                        <td class="text-start ">{{ modulo.descripcion }}</td>
                        <td class="text-center" >{{ modulo.fecha_inicio }}</td>
                        <td class="text-center" >{{ modulo.fecha_fin }}</td>
                        <td class="text-center">
                        <button @click="verCategoria(modulo)" class="badge badge-sm bg-gradient-success">
                            <i class="far fa-eye" aria-hidden="true"></i>
                        </button>
                        <button
                            @click="editarCategoria(modulo)"
                            class="badge badge-sm bg-gradient-warning">
                            <i class="far fa-edit" aria-hidden="true"></i>
                        </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>

import Preloader from "../components/Preloader.vue";
import LeccioneService from '../../services/LeccioneService';
import ModuloService from '../../services/ModuloService';
import ArchivoService from "../../services/ArchivoService";
import Swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import SesionService from "../../services/SesionService";


    export default {
        name: 'Modulos',
        data() {
            return {
                modulos: [],
                lecciones:[],
                cargando: false,
                form: {
                    nombre: "",
                    descripcion: "",
                    fecha_inicio:"",
                    fecha_fin:"",
                    horas:0,
                    estado: 1,
                    id_curso: null,
                    codigo_modulo: 'codigo'
                },
                idcurso: this.$route.params.id_curso, 
                formErrors: {},
                modalTitle: "",
                currentModuloId: null,
                moduloselecionado:'',
                mostrarform:false,
                formleccion:{
                    titulo:'',
                    id_modulo:null,
                    estado:1
                },
                video:'',
                form_archivo:{
                    nombre:'Video',
                    tipo:'Video', //Texto, Otro
                    tipo_video:'Youtube', //Vimeo, Otro
                    enlace:'',
                    texto:'',
                    id_leccion:null,
                    estado:1
                },
                form_sesion:{
                    nombre:'Sesion',
                    tipo:'Zoom', //Meet, Otro
                    enlace:'',
                  //  fecha:'',
                  //  hora_inicio:'',
                  //  hora_fin:'',
                    id_lecciones:null,
                    estado:1
                },
                id_sesion:0,
                curso:{}
            };
        },
        created() {
            this.fetchModulos();
        },
        components: {
            Preloader,
        },
        methods: {
            async guardarModulos() {
                try {
                    if (this.currentModuloId) {
                        await ModuloService.updateModulo(
                            this.currentModuloId,
                            this.form
                        );
                        this.$swal({
                            icon: "success",
                            title: "Modulo Actualizado",
                            text: "El modulo ha sido actualizado exitosamente.",
                        });
                    } else {
                        await ModuloService.createModulo(this.form);
                        this.$swal({
                            icon: "success",
                            title: "Modulo Registrado",
                            text: "El modulo  ha sido registrada exitosamente.",
                        });
                    }
                    location.reload();
                    this.fetchModulos();
                    this.mostrarform=false;
                   // this.closeModal();
                } catch (error) {
                    if (error.response && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                    } else {
                        console.error("Error guardando el modulo", error);
                        this.$swal({
                            icon: "error",
                            title: "Error",
                            text: "Hubo un error al guardar el modulo.",
                        });
                    }
                }
            },
            nuevaLeccion(id_modulo) {
                this.formleccion.id_modulo=id_modulo;
                const inputValue ='';
                this.$swal({
                    title: "Nueva Lección",
                    input: "text",
                    inputLabel: "Nombre",
                    inputValue,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return "Ingrese Nombre de Lección";
                        }
                    }
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.formleccion.titulo=result.value;;
                        try {
                            await LeccioneService.createLecciones(this.formleccion); 
                            this.$swal({
                                icon: "success",
                                title: " Creado",
                            }).then(() => {
                                location.reload();
                            });
                        } catch (error) {
                            console.error("Error al crear:", error);
                            this.$swal({
                                icon: "error",
                                title: "Error",
                                text: "Hubo un error al crear",
                            });
                        }
                    }
                });
            },
            nuevoItem() {
                this.modalTitle = "Nuevo Modulo"
                this.currentModuloId = null;
                this.form = {
                    nombre: "",
                    descripcion: "",
                    estado: 1,
                    id_curso: this.idcurso,
                    codigo_modulo: "codigo",
                    fecha_inicio:"",
                    fecha_fin:"",
                    horas:0,
                };
                this.mostrarform=true;
            },
            async desactivarLeccion(id) {
            try {
                await LeccioneService.deleteLecciones(id);
                this.$swal({
                    icon: "success",
                    title: "Lección desactivada con éxito",
                }).then(() => {
                    location.reload();
                });
            } catch (error) {
                console.error("Error al desactivar la lección:", error);
                this.$swal({
                    icon: "error",
                    title: "Error al desactivar",
                    text: "Hubo un error al intentar desactivar la lección."
                });
            }
        },
            async fetchModulos() {
                try {
                    this.cargando = true;
                    const response = await ModuloService.getModulosPorCurso(this.idcurso);
                    this.modulos = response.data.data;
                    if(this.modulos.length>0){
                        this.curso=response.data.data[0].curso
                    }
                } catch (error) {
                    console.error("Error fetching modulos:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los módulos.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            editarModulo(m) {
                // Función para abrir el modal de edición con los datos de una categoría existente
                this.modalTitle = "Editar Modulo"; // Cambia el título del modal a "Editar"
                this.currentModuloId = m.id; // Asigna el ID de la categoría actual
                this.form = {
                    nombre: m.nombre, // Carga los datos actuales de la categoría en el formulario
                    descripcion: m.descripcion,
                    estado: 1,
                    id_curso: m.id_curso,
                    codigo_modulo: m.codigo_modulo,
                    fecha_inicio: m.fecha_inicio,
                    fecha_fin: m.fecha_fin,
                    horas: m.horas
                };
                this.mostrarform=true; 
                window.scrollTo(0,0);// Muestra el modal
            },
            irMateriales(id) {
                this.$router.push({
                    name: "Materiales",
                    params: {
                        id_leccion: id
                    },
                })
            },

            

            editarLeccion(leccion) {
                this.modalTitle = "Editar Lección";
                this.currentLeccionId = leccion.id;
                this.formleccion = {
                    titulo: leccion.titulo,
                    id_modulo:leccion.id_modulo,
                    estado:1
                };
                // SweetAlert popup para editar la lección
                this.$swal({
                    title: this.modalTitle,
                    html:
                        `<label>Título:</label><input id="swal-input1" class="swal2-input" value="${leccion.titulo}">`, 
                    showCancelButton: true,
                    confirmButtonText: 'Guardar',
                    preConfirm: () => {
                        const titulo = document.getElementById('swal-input1').value;
                        if (!titulo) {
                            this.$swal.showValidationMessage("Todos los campos son obligatorios");
                        } else {
                            this.formleccion.titulo = titulo;
                            return { titulo};
                        }
                    },
                    }).then(async (result) => {                                                             
                        if (result.isConfirmed) {
                            try {
                                await LeccioneService.updateLecciones(this.currentLeccionId, this.formleccion);
                                this.$swal("Lección Actualizada", "Los cambios han sido guardados.", "success");
                                this.fetchModulos(); // Recargar lista de lecciones si es necesario
                            } catch (error) {   
                                this.$swal("Error", "Hubo un problema al actualizar la lección.", "error");
                                console.error("Error actualizando lección:", error);
                            }
                        }
                    });
                },

            async verLecciones(id) {
                this.moduloselecionado=id
            try {
                this.cargando = true;
                const response = await LeccioneService.getLeccionesPorModulo(id);
                this.lecciones = response.data.data; // Asigna las lecciones recibidas
            } catch (error) {
                console.error("Error al cargar las lecciones:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar las lecciones.",
                });
            } finally {
                this.cargando = false; // Finaliza el estado de carga
            }
        },
        verModulo(modulo) {
            const detalles =`<div style="display: flex; flex-wrap: wrap;">
                <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                    <p><strong>Nombre:</strong> ${modulo.nombre}</p>
                    <p><strong>Descripción:</strong> ${modulo.descripcion}</p>
                    </div>
                    <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                        <p><strong>Estado:</strong> ${modulo.estado ? "Activo" : "Inactivo"}</p>
                        <p><strong>Creado:</strong> ${modulo.created_at}</p>
                        <p><strong>Actualizado:</strong> ${modulo.updated_at}</p>
                        </div>
                        </div>`;
                        this.$swal({
                            icon: "info",
                            title: "Detalles del modulo",
                            html: detalles,
                            customClass: {
                                popup: "swal-wide",
                            },
                            width: "600px",
                        });
                    },
                    
                    verLeccion(leccion) {
                        const detalles = `<div style="display: flex; flex-wrap: wrap;">
                            <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                                <p><strong>Título:</strong> ${leccion.titulo}</p>
                                <p><strong>Descripción:</strong> ${leccion.descripcion}</p>
                                </div>
                                <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                                    <p><strong>Estado:</strong> ${leccion.estado ? "Activo" : "Inactivo"}</p>
                                    <p><strong>Creado:</strong> ${leccion.created_at}</p>
                                    <p><strong>Actualizado:</strong> ${leccion.updated_at}</p>
                                    </div>
                                    </div>`;
                                    this.$swal({
                                        icon: "info",
                                        title: "Detalles de la Lección",
                                        html: detalles,
                                        customClass: {
                                            popup: "swal-wide",
                                        },
                                        width: "600px",
                                    });
                                },
                                showModal() {
                                    const modal = document.getElementById("principalModal");
                                    modal.classList.add("show");
                                    modal.style.display = "block";
                                    modal.setAttribute("aria-modal", "true");
                                    modal.removeAttribute("aria-hidden");
                                },
                                closeModal() {
                                    const modal = document.getElementById("principalModal");
                                    modal.classList.remove("show");
                                    modal.style.display = "none";
                                    modal.setAttribute("aria-hidden", "true");
                                    modal.removeAttribute("aria-modal");
                                    this.formErrors = {};
                                },
                                async desactivarModulo(id) {
                                    try {
                                        await ModuloService.desactivarModulo(id);
                                        this.$swal({
                                            icon: "success",
                                            title: " Desactivado",
                                        }).then(() => {
                                            location.reload();
                                        });
                                    } catch (error) {
                                        console.error("Error desactivar:", error);
                                        this.$swal({
                                            icon: "error",
                                            title: "Error",
                                            text: "Hubo un error al desactivar"
                                        });
                                    }
                                },
                                async videoLeccion(id) {
                                    try {
                                        const response = await ArchivoService.getVideo(id);
                                        const video = response.data.data;

                                        // Si no hay archivo, inicializar datos en blanco
                                        const videoCode = video ? video.enlace : "";  //enlace
                                        const videoType = video ? video.tipo_video : ""; //tipo_video

        // Mostrar modal para ingresar/editar el código del video y tipo de video
                this.$swal({
                    title: video ? "Editar  Video" : "Agregar de Video",
                    html: `
                        <label for="videoType">Tipo de Video:</label>
                        <select id="videoType" class="swal2-input">
                            <option value="Youtube" ${videoType === "Youtube" ? "selected" : ""}>Youtube</option>
                            <option value="Vimeo" ${videoType === "Vimeo" ? "selected" : ""}>Vimeo</option>
                            <option value="Otro" ${videoType === "Otro" ? "selected" : ""}>Otro</option>
                        </select>                       
                        <input type="text" id="videoCode" class="swal2-input" placeholder="Código de video" value="${videoCode}">
                    `,
                    showCancelButton: true,
                    confirmButtonText: "Guardar",
                    preConfirm: () => {
                        const selectedType = document.getElementById('videoType').value;
                        const enteredCode = document.getElementById('videoCode').value;
                        if (!enteredCode) {
                            Swal.showValidationMessage("Ingrese el código del video");
                        }
                        
                        this.form_archivo.tipo_video=selectedType;
                        this.form_archivo.enlace = String(enteredCode);
                        this.form_archivo.id_leccion=id;
                        
                        return { tipo_video: selectedType, enlace: enteredCode };
                    }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                if (video) {
                                    const updateResponse = await ArchivoService.updateVideos(video.id, this.form_archivo);
                                    this.form_archivo = updateResponse.data.data;
                                    this.$swal("Éxito", "El código del video ha sido actualizado.", "success");
                                } else {
                                    const createResponse = await ArchivoService.createVideo(this.form_archivo);
                                    this.form_archivo = createResponse.data.data;
                                    this.$swal("Éxito", "El código del video ha sido creado.", "success");
                            
                                }
                            } catch (error) {
                                console.error("Error al actualizar el video:", error);
                                this.$swal("Error", "No se pudo actualizar el video.", "error");
                            }
                        }
                    });
                } catch (error) {
                            console.error("Error:", error);
                            this.$swal("Error", "No se pudo obtener o actualizar el video.", "error");
                        }
                    }
                    ,
            async sesionLeccion(id) {
            try {
                const response = await SesionService.getSesiones(id);
                const sesion = response.data.data;
               // this.id_sesion=sesion.id;
               // console.log(sesion.id);
        
                const sesionCode = sesion ? sesion.enlace : "";  //enlace
                const sesionType = sesion ? sesion.tipo: ""; //tipo_sesion
                const sesionFecha = sesion ? sesion.fecha: ""; //tipo_sesion
                const sesionHoraInicio = sesion ? sesion.hora_inicio.split(':').slice(0, 2).join(':') : ""; // Extraer HH:mm
                const sesionHoraFin = sesion ? sesion.hora_fin.split(':').slice(0, 2).join(':') : ""; // Extraer HH:mm

                console.log("Hora de Inicio Formateada:", sesionHoraInicio);
                console.log("Hora de Fin Formateada:", sesionHoraFin);

        // Mostrar modal para ingresar/editar el código del video y tipo de video
                this.$swal({
                    title:  sesion ? "Editar Sesión" : "Agregar de Sesión",
                    html: `
                        <label for="sesionType">Tipo de Sesión:</label>
                        <select id="sesionType" class="swal2-input">
                            <option value="zoom" ${sesionType === "zoom" ? "selected" : ""}>Zoom</option>
                            <option value="meet" ${sesionType === "meet" ? "selected" : ""}>Meet</option>
                            <option value="otro" ${sesionType === "otro" ? "selected" : ""}>Otro</option>
                        </select>     
                        <br>  
                        <label for="sesionCode">Enlace:</label>           
                        <input type="text" id="sesionCode" class="swal2-input" placeholder="Código de sesión" value="${sesionCode}">
                        <br>
                        <label for="sesionCode">Fecha:</label>          
                        <input type="date" id="sesionFecha" class="swal2-input"  value="${sesionFecha}">
                        <br> 
                        <label for="sesionHoraInicio">Hora de Inicio:</label>         
                        <input type="time" id="sesionHoraInicio" class="swal2-input" value="${sesionHoraInicio}">
                        <br>
                        <label for="sesionHoraFin">Hora de Fin:</label>    
                        <input type="time" id="sesionHoraFin" class="swal2-input" value="${sesionHoraFin}">
                        `,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        preConfirm: () => {
                            const selectedType = document.getElementById('sesionType').value;
                            const enteredCode = document.getElementById('sesionCode').value;
                            const enteredFecha = document.getElementById('sesionFecha').value;
                            const enteredHoraInicio= document.getElementById('sesionHoraInicio').value;
                            const enteredHoraFin = document.getElementById('sesionHoraFin').value;
                            if (!enteredCode) {
                                Swal.showValidationMessage("Ingrese enlace");
                            }
                            this.form_sesion.tipo=selectedType;
                            this.form_sesion.enlace = String(enteredCode);
                            this.form_sesion.id_lecciones=id;
                            this.form_sesion.fecha=enteredFecha;
                            this.form_sesion.hora_inicio=enteredHoraInicio;
                            this.form_sesion.hora_fin=enteredHoraFin;
                            this.form_sesion.hora_inicio = `${ this.form_sesion.hora_inicio}:00`; // Formato HH:MM:00
                            this.form_sesion.hora_fin = `${this.form_sesion.hora_fin}:00`;       // Formato HH:MM:00
                            this.form_sesion.nombre='Sesion';
                            this.form_sesion.estado=1;
                        }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            try {
                                if (sesion) {
                                    const updateSesionResponse = await SesionService.updateSesion(sesion.id, this.form_sesion);
                                    this.form_sesion = updateSesionResponse.data.data;
                                    this.$swal("Éxito", "El enlace ha sido actualizado.", "success");
                                } else {
                                    const createResponse = await SesionService.createSesion(this.form_sesion);
                                    this.form_sesion = createResponse.data.data;
                                    this.$swal("Éxito", "El enlace ha sido creado.", "success");
                                }
                            } catch (error) {
                                console.error("Error al actualizar:", error);
                                this.$swal("Error", "No se pudo actualizar.", "error");
                            }
                        }
                    });
                } catch (error) {
                    console.error("Error:", error);
                    this.$swal("Error", "No se pudo obtener o actualizar.", "error");
                }
            },
        }
        }
</script>
<style scoped>
.fade {
    transition: all 0.3s ease; /* Suaviza la transición */
    opacity: 0; /* Comienza invisible */
    max-height: 0; /* Comienza con altura 0 */
    overflow: hidden; /* Oculta el contenido que sobresale */
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease; /* Tiempo de la transición */
}

.fade-enter, 
.fade-leave-to /* .fade-leave-active en <2.1.8 */ {
    opacity: 0.5; /* Cambia a visible */
    max-height: 100px; /* Ajusta según tu contenido */
}
.fade-enter-active, .fade-leave-active {
    transition: all 0.5s ease; /* Suaviza la transición */
}
.fade-enter, .fade-leave-to { /* .fade-leave-active en <2.1.8 */
    opacity: 0; /* Comienza invisible */
    max-height: 0; /* Comienza con altura 0 */
    overflow: hidden; /* Oculta el contenido que sobresale */
}

</style>
