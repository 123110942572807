import apiClient from "../axios";
export default {
    getPreguntas() {
        return apiClient.get("/preguntas");
    },

    getPreguntasPorevaluacion() {
        return apiClient.get("/preguntas/evaluacion");
    },
    getPreguntasporExamen(id) {
        return apiClient.get(`/preguntas/evaluacion/${id}`);
    },
    createPregunta(data) {
        return apiClient.post("/preguntas", data);
    },
    creatOpciones(data) {
        return apiClient.post("/opciones", data);
    },
    getOpcionesporPregunta(id) {
        return apiClient.get(`/opciones/pregunta/${id}`);
    },
    getPregunta(id) {
        return apiClient.get(`/preguntas/${id}`);
    },

    desactivarPreguntas(id) {
        return apiClient.put(`/preguntas/desactivar/${id}`);
    },
    desactivarOpcion(id) {
        return apiClient.put(`/opciones/desactivar/${id}`);
    },
    updatePregunta(id, data) {
        return apiClient.put(`/preguntas/${id}`, data, {
    
        })
    },
    updateOpcion(id, data) {
        return apiClient.put(`/opciones/${id}`, data, {
    
    });

    
    },
};