// Importa la librería Axios para realizar solicitudes HTTP
import axios from 'axios';

// Crea una instancia de Axios con configuración personalizada
const apiClient = axios.create({
    // Define la URL base para todas las solicitudes, obtenida de una variable de entorno
    baseURL: process.env.VUE_APP_API_URL + '/api',

    // Indica si las solicitudes deben incluir credenciales (cookies, cabeceras HTTP de autenticación, etc.)
    withCredentials: false,

    // Define las cabeceras HTTP predeterminadas para las solicitudes
    headers: {
        // Especifica que se aceptan respuestas en formato JSON
        Accept: 'application/json',
        // Especifica que el contenido de las solicitudes será JSON
        'Content-Type': 'application/json',
    },
});

// Añade un interceptor a las solicitudes para modificar la configuración antes de que se envíen
apiClient.interceptors.request.use(
    config => {
        // Busca un token de autenticación en localStorage
        const token = localStorage.getItem('authToken');

        // Si el token existe, lo añade a las cabeceras de la solicitud
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // Retorna la configuración modificada
        return config;
    },
    error => {
        // Si ocurre un error al configurar la solicitud, rechaza la promesa con el error
        return Promise.reject(error);
    }
);

// Exporta la instancia de Axios configurada para que pueda ser utilizada en otras partes de la aplicación
export default apiClient;
