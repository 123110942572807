
import apiClient from "../axios"; // Importa el cliente API preconfigurado desde axios
export default {

  getCursos() {
    return apiClient.get("/cursos");
  },

  getCursosActivos() {
    return apiClient.get("/cursos/activos");
  },

  getCurso(id) {
    return apiClient.get(`/cursos/${id}`);  // Corregí el endpoint con el literal de plantilla correcto.
  },
  
  createCurso(data) {
    return apiClient.post("/cursos", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  
  updateCurso(id, data) {
    return apiClient.post(`/cursos/${id}`, data, { // Corregí el endpoint con el literal de plantilla correcto.
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  desactivarCurso(id) {
    return apiClient.put(`/cursos/desactivar/${id}`);
  },
};
