import apiClient from "../axios";

export default {
  getUsuario() {
    return apiClient.get("/usuarios");
  },

  getUsuariosActivos() {
    return apiClient.get("/usuarios/activos");
  },
  getDocentesActivos() {
    return apiClient.get("/usuarios/docentes/activos");
  },
  getAlumnosActivos() {
    return apiClient.get("/usuarios/alumnos/activos");
  },

  getUsuarios(id) {
    return apiClient.get(`/usuarios/${id}`);
  },

  createUsuario(data) {
    return apiClient.post("/usuarios", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateUsuario(id, data) {
    return apiClient.post(`/usuarios/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data", // Asegúrate de que todos los datos lleguen en este formato
      },
    });
  },


  desactivarUsuario(id) {
    return apiClient.put(`/usuarios/desactivar/${id}`);
  },
};
