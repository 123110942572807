import apiClient from "../axios";

export default {
    getCertificados() {
        return apiClient.get("/certificados");
    },

    getCertificadosActivos() {
        return apiClient.get("/certificados/activos");
    },

    createCertificado(data) {
        return apiClient.post("/certificados", data);
    },

    updateCertificado(id, data) {
        return apiClient.put(`/certificados/${id}`, data);
    },

    desactivarCertificados(id) {
        return apiClient.put(`/certificados/desactivar/${id}`);
    },
    //Certificados por alumno 
    getCertificadoCurso(id_curso, id_usuario) {
        return apiClient.get(`/certificado/curso/${id_curso}/usuario/${id_usuario}`);
    },
    
    getCertificadoModulo(id_modulo, id_usuario) {
        return apiClient.get(`/certificado/modulo/${id_modulo}/usuario/${id_usuario}`);
    },

};
