import apiClient from "../axios";
export default {
    getLecciones() {
        return apiClient.get("/lecciones");
    },

    getLeccione(id) {
        return apiClient.get(`/lecciones/${id}`);
    },
    
    createLecciones(data) {
        return apiClient.post("/lecciones", data);
    },
    getLeccionesPorModulo(id) {
        return apiClient.get(`/lecciones/modulo/${id}`);
    },

    updateLecciones(id, data) {
        return apiClient.put(`/lecciones/${id}`, data,{
        });
    },
    
    deleteLecciones(id) {
        return apiClient.put(`/lecciones/desactivar/${id}`);
    }

};