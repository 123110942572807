import apiClient from "../axios";
export default {
  
    getSesiones(id) {
        return apiClient.get(`/sesion/leccion/${id}`);
    },

    updateSesion(id, data) {
        return apiClient.put(`/sesiones/${id}`, data, {
        });
    },  

    createSesion(data) {
        return apiClient.post("/sesiones", data);
    },
    


};