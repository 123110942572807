import apiClient from "../axios";

export default {
    getMateriales() {
        return apiClient.get("/materiales");
    },
    getMaterialesPorCurso(id) {
        return apiClient.get(`/materiales/leccion/${id}`);
    },
    getMaterial(id) {
        return apiClient.get(`/materiales/${id}`);
    },
    createMaterial(data) {
        return apiClient.post("/materiales", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    updateMaterial(id, data) {
        return apiClient.post(`/materiales/${id}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    desactivarMaterial(id) {
        return apiClient.put(`/materiales/desactivar/${id}`);
    },
};
