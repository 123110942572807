/*Importación de Módulos*/
import { createRouter, createWebHistory } from "vue-router";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Categoria from "../views/DashboardCliente/Categoria.vue";
import Matricula from "../views/DashboardCliente/Matricula.vue";
import Usuarios from "../views/DashboardCliente/Usuarios.vue";
import Cursos from "../views/DashboardCliente/Cursos.vue";
import Modulos from "../views/DashboardCliente/Modulos.vue";
import Lecciones from "../views/DashboardCliente/Lecciones.vue";
import Sesiones from "../views/DashboardCliente/Sesiones.vue";
import Materiales from "../views/DashboardCliente/Materiales.vue";
import Certificado from "../views/DashboardCliente/Certificado.vue";
import Evaluaciones from "../views/DashboardCliente/Evaluaciones.vue";
import Login from "../views/Pages/Login.vue";
import Preguntas from "../views/DashboardCliente/Preguntas.vue";
import Calificaciones from "../views/DashboardCliente/Calificaciones.vue";
import Clientes from "../views/DashboardCliente/Clientes.vue";
import DetalleModulos from "../views/DashboardCliente/DetalleModulos.vue";
import Home from "../views/Pages/Home.vue";
import DetalleEvaluaciones from "../views/DashboardCliente/DetalleEvaluaciones.vue";
import Notificaciones from "../views/DashboardCliente/Notificaciones.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/inicio",
  },
  {
    path: "/inicio",
    name: "Home",
    component: Home,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-categorias",
    name: "Categoria",
    component: Categoria,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },
  {
    path: "/cliente-usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Inicio de Sesión",
      auth: false,
    },
  },
  {
    path: "/cliente-cursos",
    name: "Cursos",
    component: Cursos,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-cursos/:id_curso/modulos/",
    name: "Modulos",
    component: Modulos,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-cursos/lecciones/:id_modulo",
    name: "lecciones",
    component: Lecciones,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-matricula/curso/:id_curso",
    name: "Matricula",
    component: Matricula,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-matricula/:id_curso/modulos",
    name: "DetalleModulos",
    component: DetalleModulos,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-matricula/curso/:id_curso/modulos/materiales/:id_leccion",
    name: "Materiales",
    component: Materiales,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-cursos/sesiones/:id_curso/:id_modulo/:id_leccion",
    name: "Sesiones",
    component: Sesiones,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-certificado",
    name: "Certificado",
    component: Certificado,
    meta: {
      title: "Certificado - Aula Virtual",
      auth: false,
    },
  },

  {
    path: "/cliente-evaluaciones/curso/:idcurso?",
    name: "Evaluaciones",
    component: Evaluaciones,
    meta: {
      title: "Evaluaciones - Aula Virtual",
      auth: true,
    },
  },
  {
    path: "/cliente-evaluaciones/curso/:idcurso?/evaluacion/:idevaluacion?",
    name: "DetalleEvaluaciones",
    component: DetalleEvaluaciones,
    meta: {
      title: "DetalleEvaluaciones - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-evaluaciones",
    name: "Calificaciones",
    component: Calificaciones,
    meta: {
      title: "Calificaciones - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/preguntas/:idevaluacion",
    name: "Preguntas",
    component: Preguntas,
    meta: {
      title: "Categorias - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/cliente-notificaciones",
    name: "Notificaciones",
    component: Notificaciones,
    meta: {
      title: "Notificaciones - Aula Virtual",
      auth: true,
    },
  },

  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },

  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("authToken");
  const user = JSON.parse(localStorage.getItem("user"));

  if (to.matched.some((record) => record.meta.auth)) {
    if (!isAuthenticated || !user) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
