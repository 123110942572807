import apiClient from "../axios";

export default {

getEvaluaciones() {
    return apiClient.get("/evaluacion");
},
getEvaluacionesActivas() {
    return apiClient.get("/evaluaciones/activos");
},
getEvaluacionesPorCurso(id) {
    return apiClient.get(`/evaluaciones/curso/${id}`);
},
getEvaluacion(id) {
    return apiClient.get(`/evaluacion/${id}`);
},
createEvaluacion(data) {
    return apiClient.post("/evaluacion", data);
},
updateEvaluacion(id, data) {
    return apiClient.put(`/evaluacion/${id}`, data);
    },
desactivarEvaluacion(id) {
    return apiClient.put(`/evaluacion/desactivar/${id}`);
},
};
