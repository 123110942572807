import apiClient from "../axios";

export default {
  getRespuestasPorPregunta(idPregunta) {
    return apiClient.get(`/respuestas/pregunta/${idPregunta}`);
  },
  updateRespuesta(idRespuesta, data) {
    return apiClient.put(`/respuestas/${idRespuesta}`, data);
  },
  getRespuestasPorDetalle(id_detalleevaluacion) {
    return apiClient.get(`/respuestas/detalleevaluacion/${id_detalleevaluacion}`);
  },
};
