<template>
    <div class="card">
        <!-- Encabezado de la tarjeta con título y botón de nuevo -->
        <div class="card">
            <div class="card-header pb-3 d-flex align-items-center justify-content-between flex-wrap">
                <!-- Título de la sección -->
                <h4 class="mb-0 text-primary">Matrículas</h4>
        
                <!-- Botón de acción -->
                <div class="d-flex align-items-center gap-2">
                <button 
                    class="btn btn-primary btn-sm d-flex align-items-center gap-1 mt-2 mt-md-0" 
                    @click="nuevoItem()">
                    <i class="fas fa-plus"></i>
                    <span>Nueva Matrícula</span>
                </button>
                <button 
                        class="btn btn-sm btn-outline-primary d-flex align-items-center gap-1" 
                        @click="$router.go(-1)">
                        <i class="fas fa-arrow-left"></i>
                        <span>Regresar a cursos</span>
                    </button>
            </div>
        </div>
        <Preloader :visible="cargando" />            
        <!-- Importación Masiva de Matrículas -->
        <div class="importacion-container m-4 p-4 border rounded shadow-sm bg-light">
            <!-- Título y descripción -->
            <h3 class="text-primary mb-3">Importación Masiva de Matrículas</h3>
            <p class="text-muted mb-4">Selecciona un archivo Excel para importar las matrículas.</p>
            <!-- Campo para seleccionar archivo -->
            <div class="mb-4">
                <label for="fileInputMatriculas" class="form-label">Archivo Excel</label>
                <input
                    class="form-control"
                    type="file"
                    id="fileInputMatriculas"
                    @change="onFileChange"
                    accept=".xlsx, .xls"
                />
            </div>
            <!-- Contenedor para botones -->
            <div class="d-flex flex-column flex-md-row align-items-center gap-3">
                <!-- Botón para subir el archivo Excel de Matrículas -->
                <button
                    class="btn btn-primary flex-fill flex-md-grow-1"
                    @click="uploadFile"
                    :disabled="!file"
                >
                    <i class="bi bi-upload me-2"></i> Subir Archivo Excel de Matrículas
                </button>
                <!-- Enlace para descargar la plantilla -->
                <a
                    href="https://app.micampusenlinea.com/template/plantillas.rar"
                    target="_blank"
                    class="btn btn-outline-secondary flex-fill flex-md-grow-0"
                    role="button"
                    aria-label="Descargar Plantilla de Matrículas"
                >
                    <i class="bi bi-download me-2"></i> Descargar Plantilla
                </a>
            </div>
        </div>
    </div>    
        <!-- Tabla Responsiva -->
        <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-4">
            <table class="table table-bordered table-striped mb-0" ref="itemtabla">
            <thead>
                <tr>
                <th class="text-start text-secondary opacity-7">ID</th>
                <th class="text-start text-secondary opacity-7">Código</th>
                <th class="text-start text-secondary opacity-7">Curso</th>
                <th class="text-start text-secondary opacity-7">Alumno</th>
                <th class="text-center text-secondary opacity-7">Fecha de Matricula</th>
                <th class="text-center text-secondary opacity-7">Fecha de Finalización</th>
                <th class="text-center text-secondary opacity-7">Monto</th>
                <th class="text-center text-secondary opacity-7">Estado</th>
                <th class="text-center text-secondary opacity-7">Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(matricula, index) in matriculas" :key="matricula.id">
                <td class="text-start">{{ index + 1 }}</td>
                <td class="text-start">{{ matricula.codigo_matricula }}</td>
                <td class="text-start">{{ matricula.curso.nombre }}</td>
                <td class="text-start">{{ matricula.usuario.nombres_completos }}</td>
                <td class="text-center">{{ matricula.fecha_inscripcion }}</td>
                <td class="text-center">
                    {{ matricula.fecha_vencimiento ? matricula.fecha_vencimiento : "Sin fecha de vencimiento" }}
                </td>
                <td class="text-center">{{ matricula.monto }}</td>
                <td class="text-center">
                    <span
                    class="badge"
                    :class="matricula.estado === 'Activo' ? 'bg-gradient-success' : 'bg-danger'"
                    >
                    {{ matricula.estado === 'Activo' ? 'Activa' : 'Suspendida' }}
                    </span>
                </td>
                <td class="text-center">
                    <button @click="editarMatricula(matricula)" class="badge badge-sm bg-gradient-warning" title="Editar">
                    <i class="far fa-edit" aria-hidden="true"></i>
                    </button>
                    <button @click="desactivarMatricula(matricula.id)" class="badge badge-sm bg-gradient-danger" title="Desactivar">
                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                    </button>
                    <button @click="SuspenderMatricula(matricula.id, matricula.estado)" class="badge badge-sm bg-gradient-primary" :title="matricula.estado === 'Activo' ? 'Suspender' : 'Activar'">
                    <i :class="matricula.estado === 'Activo' ? 'fas fa-lock' : 'fas fa-unlock'"></i>
                    </button>
                </td>
                </tr>
            </tbody>
            </table>
        </div>
        </div>
        <!-- Modal -->
        <div 
        class="modal fade" 
        id="principalModal" 
        data-bs-backdrop="true" 
        tabindex="-1"
        aria-labelledby="principalModalLabel" 
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Cabecera del modal -->
                <div class="modal-header">
                <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                <form id="premioForm">
                    <div class="row">
                    <!-- Campo Fecha de Inscripción -->
                    <div class="col-12 col-sm-6 mb-3">
                        <label for="fecha_inscripcion" class="form-label">Fecha de Inscripción</label>
                        <input type="date" class="form-control" id="fecha_inscripcion" v-model="form.fecha_inscripcion" />
                        <div class="text-danger" v-if="formErrors.fecha_inscripcion">{{ formErrors.fecha_inscripcion }}</div>
                    </div>
                    <!-- Campo Fecha de Finalización -->
                    <div class="col-12 col-sm-6 mb-3">
                        <label for="fecha_vencimiento" class="form-label">Fecha de Finalización</label>
                        <input type="date" class="form-control" id="fecha_vencimiento" v-model="form.fecha_vencimiento" />
                        <div class="text-danger" v-if="formErrors.fecha_vencimiento">{{ formErrors.fecha_vencimiento }}</div>
                    </div>
                    <!-- Campo Alumno -->
                    <div class="mb-3">
                        <label for="alumno" class="form-label">Alumno</label>
                        <v-select
                        :options="alumnos"
                        label="nombres_completos"
                        :reduce="alumno => alumno.id"
                        v-model="form.id_usuario"
                        class="w-100"
                        ></v-select>
                        <div class="text-danger" v-if="formErrors.id_usuario">{{ formErrors.id_usuario }}</div>
                    </div>
                    <!-- Campo Monto -->
                    <div class="mb-3 col-sm-6">
                        <label for="monto" class="form-label">Monto</label>
                        <input type="number" class="form-control" id="monto" v-model="form.monto" />
                        <div class="text-danger" v-if="formErrors.monto">{{ formErrors.monto }}</div>
                    </div>
                    </div>  
                    <div class="row">
                    <!-- Campo Código de Matrícula (Visible solo en edición) -->
                    <div class="col-12 col-sm-6 mb-3" v-if="currentMatriculaId">
                        <label for="codigo_matricula" class="form-label">Código de Matrícula</label>
                        <input type="text" class="form-control" id="codigo_matricula" v-model="form.codigo_matricula" disabled />
                        <div class="text-danger" v-if="formErrors.codigo_matricula">{{ formErrors.codigo_matricula }}</div>
                    </div>
                    </div>
                </form>
                </div>
                <!-- Pie del modal -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
                    <button type="button" class="btn btn-primary" @click="guardarMatricula" :disabled="cargando">
                        {{ cargando ? 'Guardando...' : 'Guardar' }}
                </button>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Preloader from "../components/Preloader.vue";
import MatriculaService from "../../services/MatriculaService";
import "vue-select/dist/vue-select.css";
import UsuarioService from "../../services/UsuarioService";
import axios from "axios";
import $ from 'jquery';

export default {
    name: "Matriculas",
    data() {
        return {
            matriculas: [],
            alumnos:[],
            cargando: false,
            modalTitle: "",
            idcurso: this.$route.params.id_curso, 
            currentMatriculaId: null,
            form: {
                fecha_inscripcion: '',
                fecha_vencimiento: null,
                estado: 1,
                id_usuario: '',
                id_registrador: 11,//Cambiar por usuario logueado
                id_curso: '',
                codigo_matricula: '',
                monto: '',
            },
            formErrors: {},
            file: null,
        };
    },
    components: {
            Preloader,
            
        },
    methods: {
        obtenerFechaActual() {
            const fecha = new Date();
            const anio = fecha.getFullYear();
            const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses de 0 a 11, así que sumamos 1
            const dia = String(fecha.getDate()).padStart(2, '0');
            return `${anio}-${mes}-${dia}`; // Formato YYYY-MM-DD
        },

        onFileChange(event) {
            this.file = event.target.files[0];
            console.log("Archivo seleccionado:", this.file);
            console.log("Tipo MIME:", this.file.type); // Muestra el MIME del archivo
            console.log("Nombre:", this.file.name); // Muestra el nombre del archivo
        },
        async uploadFile() {
            if (!this.file) return;
            this.cargando = true;
            const formData = new FormData();
            formData.append('file', this.file);
            console.log(formData);
            
            try {
                const apiUrl = `${process.env.VUE_APP_API_URL}/api/import-matriculas`;
                const response = await axios.post(apiUrl,  formData, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
                this.$swal({
                    icon: "success",
                    title: "Importación Completa",
                    text: response.data.message || "Las matrículas fueron importadas correctamente.",
                });
               // this.fetchMatriculas();
            } catch (error) {
                console.error("Error al importar matrículas:", error);
                this.$swal({
                    icon: "error",
                    title: "Error de Importación",
                    text: error.response.data.message || "Ocurrió un error al importar el archivo.",
                });
            } finally {
        this.cargando = false; // Finaliza el estado de carga
            }
        },
        async fetchMatriculas() {
            try {
                this.cargando = true;
                const response = await MatriculaService.getMatriculasPorCurso(this.idcurso);
                this.matriculas = response.data.data;
                this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [
                                [0, 'desc']
                            ],
                            pageLength: 20 // Mostrar 20 filas por página
                        });
                    });

            } catch (error) {
                console.error("Error fetching matriculas:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar las matrículas.",
                });
            } finally {
                this.cargando = false;
            }
        },
        async fetchAlumnos() {
            try {
                this.cargando = true;
                const response = await UsuarioService.getAlumnosActivos();
                this.alumnos = response.data.data;
            } catch (error) {
                console.error("Error fetching alumnos:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar los alumnos.",
                });
            } finally {
                this.cargando = false;
            }
        },
        nuevoItem() {
            this.modalTitle = "Nueva Matricula";
            this.currentMatriculaId = null;
            this.form = {
                fecha_inscripcion: this.obtenerFechaActual(),
                fecha_vencimiento: null,
                estado: 1,
                id_usuario: '',
                id_registrador: 11,
                id_curso: this.idcurso,
                codigo_matricula:'',
                monto: '',
            };
            this.showModal();
        },
        async guardarMatricula() {
                try {
                    if (this.currentMatriculaId) {   
                        // Actualizar matrícula existente                    
                        await MatriculaService.updateMatricula(
                            this.currentMatriculaId,
                            this.form
                        );
                        this.$swal({
                            icon: "success",
                            title: "Matricula Actualizada",
                            text: "La Matrícula ha sido actualizada exitosamente.",
                        });
                    } else {
                        this.form.codigo_matricula='M'+this.form.id_usuario+'-'+this.form.id_curso
                        await MatriculaService.createMatricula(this.form);
                        this.$swal({
                            icon: "success",
                            title: "Matricula Registrada",
                            text: "La Matricula ha sido registrada exitosamente.",
                        });
                    }
                    this.fetchMatriculas();
                    this.closeModal();
                } catch (error) {
                    if (error.response && error.response.data.errors) {
                        // Mostrar errores específicos de cada campo
                        this.formErrors = error.response.data.errors;
                        console.error("Errores en la validación de campos:", this.formErrors);
                        this.$swal({
                            icon: "error",
                            title: "Error en los campos",
                            text: "Por favor, revisa los errores en los campos y vuelve a intentarlo.",
                        });
                    } else {
                        console.error("Error guardando certificado:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error inesperado",
                            text: "Hubo un error al guardar el certificado.",
                        });
                    }
                }
            },
        editarMatricula(matricula) {
            this.modalTitle = "Editar Matricula";
            this.currentMatriculaId = matricula.id;
            this.form = {
                fecha_inscripcion: matricula.fecha_inscripcion,
                fecha_vencimiento: matricula.fecha_vencimiento,
                estado: 1,
                id_usuario: matricula.id_usuario,
                id_registrador: matricula.id_usuario,
                id_curso: this.idcurso,
                codigo_matricula:matricula.codigo_matricula,
                monto: matricula.monto,
            };
            this.showModal();
            
        },
        async desactivarMatricula(id) {
            try {
                    await MatriculaService.desactivarMatricula(id);
                    this.$swal({
                        icon: "success",
                        title: "Matricula Desactivada",
                        text: "La Matricula ha sido desactivada",
                    }).then(() => {
                        location.reload();
                    });
                } catch (error) {
                    console.error("Error desactivando la Matricula:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al desactivar la Matricula.",
                    });
                }
            
        },
        async SuspenderMatricula(id,estado) {
            let titulo="";
            let icon='';
            if(estado=='Activo'){
                titulo='Matricula Suspendida'
                icon='info'
            }
            else
            {
                titulo='Matricula Activada'
                icon='success'
            }
        
            try {
                    await MatriculaService.SuspenderMatricula(id);
                    this.$swal({
                        icon: icon,
                        title: titulo
                    }).then(() => {
                        location.reload();
                    });
                } catch (error) {
                    console.error("Error en la Matricula:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: error,
                    });
                }
            
        },
        showModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.add("show");
            modal.style.display = "block";
            modal.setAttribute("aria-modal", "true");
            modal.removeAttribute("aria-hidden");
        },
        closeModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.remove("show");
            modal.style.display = "none";
            modal.setAttribute("aria-hidden", "true");
            modal.removeAttribute("aria-modal");
            this.formErrors = {};
        },
    },
    mounted() {
        this.fetchMatriculas();
        this.fetchAlumnos();
    },
};
</script>


<style>
.modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
  }
.ck-editor__editable_inline {
    min-height:200px;
}

.ql-editor {
    background-color: white !important; /* Fondo blanco para el editor */
    color: black !important; /* Texto negro */
}

.ql-toolbar {
    background-color: #002162 !important; /* Color claro para la barra de herramientas */
    border: 1px solid #ccc;
}

.ql-container {
    border: 1px solid #ccc;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: 95%;
    }
}
</style>