<template>
    <div class="card ">
        <div class="card-body px-0 pt-0 pb-2">
        <div class="card-header pb-0">
            <h4 class="mb-0 text-primary">Cursos</h4>
            <Preloader :visible="cargando" />
            <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-4">
                <table class="table table-bordered table-striped mb-0" ref="itemtabla">
                    <thead>
                        <tr>
                            <th class="text-center px-3 py-2">ID</th>
                            <th class="text-start px-3 py-2">Nombre</th>
                            <th class="text-center px-3 py-2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="curso in cursos" :key="curso.id">
                            <td class="text-center ">{{ curso.id }}</td>
                            <td class="text-start">{{ curso.nombre }}</td>
                            <td class="text-center">
                                <button @click="verDetalleModulos(curso.id)" class="badge badge-sm" style="background-color:blueviolet">
                                    Evaluaciones
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Preloader from "../components/Preloader.vue";
    import CategoriaService from '../../services/CategoriaService';
    import "vue-select/dist/vue-select.css";
    import CursoService from "../../services/CursoService";
    import $ from 'jquery';

    

    export default {
        name: "Evaluaciones",
        data() {
            return {
                cursos: [],
                docentes: [],
                clientes: [],
                cargando: false,
            
                form: {
                    nombre: '',
                    descripcion: '',
                    requisitos: '',
                    contenido: '',
                    nivel: 'Básico',
                    horas: '',
                    fecha_inicio: '',
                    fecha_fin: '',
                    costo: '',
                    estado: 1,
                    id_categoria: '',
                    id_docente: '',
                    id_cliente: '',
                    codigo_curso: '',
                    imagen: '',
                },
                formMatricula: {
                    fecha_inscripcion: '',
                    estado: 1,
                    id_usuario: '',
                    id_registrador: '',
                    id_curso: '',
                    codigo_matricula: '',
                    monto: '',
                },
                //formErrors: {},
                modalTitle: "",
                currentCursoId: null,
                file: null,
            };
        },
        created() {
            this.fetchCursos();
            
        },
        components: {
            Preloader,            
        },

        methods: {
            verDetalleModulos(id) {
                this.$router.push({
                    name: "Evaluaciones",
                    params: {
                        idcurso: id
                    },
                })
            },

            

            async fecthCategorias() {
                try {
                    this.cargando = true;
                    const response = await CategoriaService
                .getCategoriasActivas(); // Llama al servicio de categorías
                    this.categorias = response.data.data; // Almacena las categorías
                } catch (error) {
                    console.error("Error fetching categorias:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar las categorías.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
            async fetchCursos() {
                try {
                    this.cargando = true;
                    const response = await CursoService.getCursosActivos();
                    this.cursos = response.data.data;
                /*  this.cursos = cursos.map(curso => {
                    return {
                        ...curso,
                        fecha_inicio: this.convertToISODate(curso.fecha_inicio),
                        fecha_fin: this.convertToISODate(curso.fecha_fin)
                    };
                    });*/
                    this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [
                                [0, 'desc']
                            ],
                            pageLength: 20 // Mostrar 20 filas por página
                        });
                    });

                } catch (error) {
                    console.error("Error fetching cursos:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los cursos.",
                    });
                } finally {
                    this.cargando = false;
                }
            },
        
    
        },
    };
</script>

<style>
.ck-editor__editable_inline {
    min-height:200px;
}

.ql-editor {
    background-color: white !important; /* Fondo blanco para el editor */
    color: black !important; /* Texto negro */
}

.ql-toolbar {
    background-color: #002162 !important; /* Color claro para la barra de herramientas */
    border: 1px solid #ccc;
}

.ql-container {
    border: 1px solid #ccc;
}
</style>