import apiClient from "../axios";

export default {
    getNotificacion() {
        return apiClient.get("/notificaiones");
    },

    getNotificacionesActivos() {
        return apiClient.get("/notificaciones/activos");
    },

    createNotificaciones(data) {
        return apiClient.post("/notificaciones", data);
    },

    updateNotificaciones(id, data) {
        return apiClient.put(`/notificaciones/${id}`, data);
    },

    desactivarNotificaciones(id) {
        return apiClient.put(`/notificaciones/desactivar/${id}`);
    },
};
