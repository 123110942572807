<template>
  <div class="welcome-page">
    <!-- Sección de Bienvenida -->
    <div class="welcome-header">
      <h1>👋 ¡Bienvenido, {{ user?.nombres || "Usuario" }}!</h1>
      <p>
        Este es tu panel de administración del aula virtual. Aquí podrás gestionar cursos, estudiantes, evaluaciones y notificaciones de manera sencilla.
      </p>
    </div>

    <!-- Sección de Guía Rápida -->
    <div class="guide-section">
      <h2>📖 Guía Rápida</h2>
      <ul class="guide-list">
        <li>
          <i class="guide-icon fas fa-folder"></i>
          <strong>Categorías:</strong> Organiza y administra las categorías para tus cursos.
        </li>
        <li>
          <i class="guide-icon fas fa-users"></i>
          <strong>Usuarios:</strong> Gestiona usuarios, asigna roles y revisa información importante.
        </li>
        <li>
          <i class="guide-icon fas fa-book"></i>
          <strong>Cursos:</strong> Crea, edita y elimina cursos de manera sencilla.
        </li>
        <li>
          <i class="guide-icon fas fa-certificate"></i>
          <strong>Certificados:</strong> Diseña y entrega certificados a estudiantes que completen los cursos.
        </li>
        <li>
          <i class="guide-icon fas fa-tasks"></i>
          <strong>Evaluaciones:</strong> Administra evaluaciones para tus cursos y módulos.
        </li>
        <li>
          <i class="guide-icon fas fa-user-tie"></i>
          <strong>Clientes:</strong> Consulta y gestiona la información de tus clientes.
        </li>
      </ul>
    </div>

    <!-- Nota Final -->
    <div class="final-note">
      <p>
        Si necesitas ayuda adicional, consulta la sección de "Soporte" o ponte en contacto con el administrador del sistema.
      </p>
      <p>© 2024, Todos los Derechos Reservados 💙 | Desarrollado por Conit</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")) || {}, // Datos del usuario obtenidos del localStorage
    };
  },
};
</script>

<style scoped>
/* Fondo completamente blanco */
body {
  background-color: #ffffff;
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Contenedor principal */
.welcome-page {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: #ffffff; /* Fondo blanco */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  color: #333;
}

/* Sección de bienvenida */
.welcome-header {
  text-align: center;
  margin-bottom: 30px;
}

.welcome-header h1 {
  font-size: 28px;
  font-weight: bold;
  color: #1e3c72;
}

.welcome-header p {
  font-size: 16px;
  color: #555;
}

/* Guía Rápida */
.guide-section {
  margin-bottom: 30px;
}

.guide-section h2 {
  font-size: 20px;
  color: #1e3c72;
  margin-bottom: 15px;
}

.guide-list {
  list-style: none;
  padding: 0;
}

.guide-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.guide-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 10px;
}

/* Nota Final */
.final-note {
  text-align: center;
  font-size: 14px;
  color: #777;
  margin-top: 20px;
}

.final-note p {
  margin: 5px 0;
}
</style>
