<template>
  <div class="card p-4">
    <div class="container">
      <button class="btn btn-primary ml-auto" @click="showAddForm">AGREGAR PREGUNTA</button>
      <button class="btn btn-outline-primary ml-auto" @click="$router.go(-1)" style="margin-left: 10px;">REGRESAR A LA LISTA DE EVALUACIONES</button>
      <Preloader :visible="cargando" />
      <div v-if="showForm">
        <div class="card p-4">
          <h4>{{ isEditing ? "Editar Pregunta" : "Agregar Pregunta" }}</h4>
          
          <div class="mb-3">
            <label for="pregunta" class="form-label">Enunciado</label>
            <input type="text" v-model="form.pregunta" class="form-control" required />
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="tipo_pregunta" class="form-label">Tipo</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" id="tipo_pregunta" v-model="form.tipo_pregunta" value="1" />
                  <label class="form-check-label" for="tipo_curso">Opción Múltiple</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="tipo_pregunta" v-model="form.tipo_pregunta" value="2" />
                  <label class="form-check-label" for="tipo_modulo">Abierta</label>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-3">
                <label for="valor" class="form-label">Valor</label>
                <input type="number" v-model="form.valor" class="form-control" required />
              </div>
            </div>
          </div>
          <div class="d-flex">
            <button @click="guardarPregunta()" class="btn btn-success" v-show="!mostrarOpciones">Agregar</button>
            <button @click="actualizarPregunta()" class="btn btn-success" v-show="mostrarOpciones">Actualizar</button>
            <button type="button" class="btn btn-secondary btn-spacing" @click="cancel">Cancelar</button>
          </div>
          
          <div v-show="mostrarOpciones">
            <div class="card p-4" style="background-color: azure;">
              <label>Alternativa</label>   
              <div class="row">
                <div class="col-md-7">                  
                  <input type="text" v-model="formOpciones.opcion" class="form-control" placeholder="Respuesta" />          
                </div>
                <div class="col-md-5">
                  <div class="d-flex">
                    <div class="form-check me-3">           
                      <input
                        class="form-check-input"
                        type="radio"
                        id="trueOption"
                        value=1
                        v-model="formOpciones.condicion"
                      >
                      <label class="form-check-label" for="trueOption">Verdadero</label>
                    </div>
                    <div class="form-check me-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="falseOption"
                        value=0
                        v-model="formOpciones.condicion"
                      >
                      <label class="form-check-label" for="falseOption">Falso</label>
                    </div>
                    <button class="btn btn-primary" @click="guardarOpcion(formOpciones,id_pregunta_actual)">Guardar</button>
                  </div>
                </div>
              </div>          
            </div>
            
            <div v-show="opcionesActuales.length > 0 || tengoOpciones">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Opciones</th>
                      <th>Condiciones</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(op, index) in opcionesActuales" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td><input type="text" v-model="op.opcion" class="form-control" placeholder="" /></td>
                      <td>
                        <div class="d-flex">
                          <div class="form-check me-3">
                            <input class="form-check-input" type="radio" id="trueOption" value=1 v-model="op.condicion">
                            <label class="form-check-label" for="trueOption">Verdadero</label>
                          </div>
                          <div class="form-check me-3">
                            <input class="form-check-input" type="radio" id="falseOption" value=0 v-model="op.condicion">
                            <label class="form-check-label" for="falseOption">Falso</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button class="btn btn-warning" @click="actualizarOpcion(op)">Actualizar</button>
                        <button class="btn btn-danger" @click="desactivarOpcion(op)">Eliminar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button type="button" class="btn btn-secondary" @click="cancel">Cerrar</button>
          </div> 
        </div>
      </div>
      <h3 class="mt-3">Lista de Preguntas</h3>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Enunciado</th>
              <th>Tipo</th>
              <th>Valor</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pregunta, index) in preguntas" :key="index">
              <td>{{ index + 1 }}</td>
              <td class="text-start">{{ pregunta.pregunta }}</td>
              <td>
                <span v-if="pregunta.tipo_pregunta === 1">Opción Múltiple</span>
                <span v-else>Pregunta Abierta</span>
              </td>
              <td>{{ pregunta.valor }}</td>
              <td>
                <button class="btn btn-sm btn-warning" @click="editarPregunta(pregunta)">
                  <i class="far fa-edit" aria-hidden="true"></i>
                </button>
                <button class="btn btn-sm btn-danger" @click="eliminarPregunta(pregunta.id)">
                  <i class="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import PreguntaService from '../../services/PreguntaService';
import Preloader from "../components/Preloader.vue";  
export default {
  data() {
    return {
      idevaluacion: this.$route.params.idevaluacion, 
      cargando: false,
      preguntas: [],
      opciones: [],
      opcionesActuales: [],
      showForm: false,
      tengoOpciones:false,
      isEditing: false,
      form: {
        pregunta: "",
        tipo_pregunta: 1,
        estado: 1,
        valor: 0,
        id_evaluacion: null,
      },
      formOpciones:{
        opcion:'',
        estado:1,
        condicion:false,
        id_pregunta:0
      },
      ippregunta:0,
      currentPreguntaId: null,
      mostrarOpciones:false,
      preguntaDetalle:{},
      id_pregunta_actual:null,
    };
  },

  created() {
    this.fetchPreguntas();
  },
  components: {
      Preloader
  },
  methods: {
    async actualizarOpcion(opcion) {    
      this.cargando = true;
       try {
        await PreguntaService.updateOpcion(opcion.id,opcion);        
      } catch (error) {
        console.log(error);        
      }finally {
        this.cargando = false; 
      }
    },
    async actualizarPregunta() {    
      this.cargando = true;
      
      try {
        await PreguntaService.updatePregunta(this.id_pregunta_actual,this.form);        
      } catch (error) {
        console.log(error);        
      }finally {
        this.cargando = false; 
      }
    },
    async desactivarOpcion(opcion) {  
      this.cargando = true;  
      try {
        await PreguntaService.desactivarOpcion(opcion.id); 
        this.ObtenerOpciones(opcion.id_pregunta)   
      } catch (error) {
        console.log(error);       
      }finally {
        this.cargando = false; 
      }
    },
    async guardarOpcion(form, id_pregunta) {
      form.id_pregunta=id_pregunta;
      this.cargando = true;
      try {
        const response = await PreguntaService.creatOpciones(form);
        console.log(response);        
        this.formOpciones={
          opcion:'',
          estado:1,
          condicion:false,
        }
        
        
        this.ObtenerOpciones(id_pregunta);
      } catch (error) {
        console.log(error);
        
      }finally {
        this.cargando = false; 
      }
    },
    async ObtenerOpciones(id_pregunta) {   
      this.opcionesActuales= []
      this.cargando = true;
      try {
        const response = await PreguntaService.getOpcionesporPregunta(id_pregunta);
        this.opcionesActuales = response.data.data;
      } catch (error) {
        console.error("Error al obtener las preguntas:", error);
      }finally {
        this.cargando = false; 
      }
    },
    async fetchPreguntas() {   
      this.cargando = true;
      try {
        const response = await PreguntaService.getPreguntasporExamen(this.idevaluacion);
        this.preguntas = response.data.data;
      } catch (error) {
        console.error("Error al obtener las preguntas:", error);
      }finally {
        this.cargando = false; 
      }
    },   
    async fetchPreguntasDetalle(id) {    
      this.cargando = true;
      try {
        const response = await PreguntaService.getPregunta(id);
        this.preguntaDetalle = response.data.data;
      } catch (error) {
        console.error("Error al obtener las preguntas:", error);
      }finally {
        this.cargando = false; 
      }
      
    },
    async guardarPregunta() {   
      this.preguntas=[]
      this.form.id_evaluacion=this.idevaluacion;
      this.cargando = true;
      try {
        const response = await PreguntaService.createPregunta(this.form);
        this.preguntaDetalle = response.data.data;
        this.opciones= this.preguntaDetalle.opciones;

        if(this.form.tipo_pregunta==1){
          this.mostrarOpciones=true
          this.id_pregunta_actual=this.preguntaDetalle.id;       

        }
        else{
          this.showForm=false
          this.form={
            pregunta: "",
            tipo_pregunta: 1,
            estado: 1,
            valor: 0,
          }
        }

        this.fetchPreguntas();
      } catch (error) {
        console.error("Error al obtener las preguntas:", error);
      }
      finally {
        this.cargando = false; 
      }
    },
    async eliminarPregunta(id) {
      console.log("Intentando eliminar pregunta con id:", id);
      this.cargando = true;  
      try {
        await PreguntaService.desactivarPreguntas(id); 
        this.$swal({
          icon: "success",
          title: "Desactivada",
          text: "Se ha desactivado",
        }).then(() => {
          this.fetchPreguntas();
        });       
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al desactivar",error
        });
      }finally {
        this.cargando = false;       
      }
    },
    showAddForm() {
      this.resetForm();
      this.mostrarOpciones=false;
      this.showForm = true;
      this.isEditing = false;
    },

    editarPregunta(pregunta) {
      this.id_pregunta_actual=pregunta.id
      this.form = { ...pregunta };
      this.ObtenerOpciones(pregunta.id)
      this.mostrarOpciones=true;
      this.showForm = true;
      this.isEditing = true;
    },
    resetForm() {
      this.form = {
        id: null,
        pregunta: "",
        tipo_pregunta: 1,
        estado: 1,
        valor: 2,
        id_evaluacion: null,
      };
    },
    cancel() {
      this.resetForm();
      this.id_pregunta_actual=0,
      this.opcionesActuales=[]
      this.showForm = false;
    } }
  
};
</script>

<style>
.btn-spacing {
  margin-left: 10px; /* Ajusta el valor según el espacio que necesites */
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}
</style>
