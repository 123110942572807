import apiClient from "../axios";
export default {

  getMatriculasPorCurso(id_curso) {
    return apiClient.get(`/matriculas/curso/${id_curso}`);
  },

  getMatriculasActivas() {
    return apiClient.get("/matriculas/activas");
  },

  getAlumnosActivos() {
    return apiClient.get("/alumnos/activos");
  },

  getMatriculas(id) {
    return apiClient.get(`/matriculas/${id}`);
  },
  

  createMatricula(data) {
    return apiClient.post("/matriculas", data, {
      headers: {
        "Content-Type": "multipart/form-data", // Asegúrate de que todos los datos lleguen en este formato
      },
    });
  },

  updateMatricula(id, data) {
    return apiClient.put(`/matriculas/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data", // Asegúrate de que todos los datos lleguen en este formato
      },
    });
  },

  desactivarMatricula(id) {
    return apiClient.put(`/matriculas/desactivar/${id}`);
  },
  SuspenderMatricula(id) {
    return apiClient.put(`/matriculas/suspender/${id}`);
  },
};
