import apiClient from "../axios";

export default {
    getVideos() {
        return apiClient.get("archivos");
    },

    getVideo(id) {
        return apiClient.get(`/archivos/lecciones/${id}`);
    },

    updateVideos(id, data) {
        return apiClient.put(`/archivos/${id}`, data, {
        });
    },  

    createVideo(data) {
        return apiClient.post("/archivos", data);
    },
};
