
import apiClient from "../axios"; 
export default {

  getModulos() {
    return apiClient.get("/modulos");
  },
  
  getModulosActivos() {
    return apiClient.get("/modulos/activos");
  },
  getModulosPorCurso(id) {
    return apiClient.get(`/modulos/curso/${id}`);
  },
  
  getModulo(id) {
    return apiClient.get(`/modulos/${id}`);
  },
  
  createModulo(data) {
    return apiClient.post("/modulos", data);
  },
  
  updateModulo(id, data) {
    return apiClient.put(`/modulos/${id}`, data);
  },

  desactivarModulo(id) {
    return apiClient.put(`/modulos/desactivar/${id}`);
  },
};
