<template>
    <div class="card">
        <div class="card-header pb-0">
            <h5>Sesiones</h5>
            <button class="btn btn-primary ml-auto" @click="nuevoItem()">NUEVO</button>
            <div class="table-responsive p-4">
                <table class="table mb-0" ref="itemtabla">
                    <thead>
                        <tr>
                            <th class="text-secondary opacity-7">ID</th>
                            <th class="text-secondary opacity-7">NOMBRE</th>
                            <th class="text-secondary opacity-7">ENLACE</th>
                            <th class="text-secondary opacity-7">TIPO</th>
                            <th class="text-secondary opacity-7">OPCIONES</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:"Sesiones",
        data() {
            return {
                sesiones:[],   
                cargando: false,
                form:{

                },
            // Aquí puedes añadir tus datos de ejemplo o cargarlos desde un API
        };
    },

        methods: {
            nuevoItem() {
        // Lógica para crear un nuevo item
            },
        }
    };
</script>