// importa una instancia personalizada de Axios para realizar solicitudes HTTP a una API 
import apiClient from "../axios"; 

export default {
  // Obtiene todas las categorías
  getCategorias() {
    return apiClient.get("/categorias");
  },
  // Obtiene solo las categorías activas
  getCategoriasActivas() {
    return apiClient.get("/categorias/activas");
  },

  // Obtiene una categoría específica por su ID
  getCategoria(id) {
    return apiClient.get(`/categorias/${id}`);
  },
  // Crea una nueva categoría
  createCategoria(data) {
    return apiClient.post("/categorias", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // Actualiza una categoría existente
  updateCategoria(id, data) {
    return apiClient.post(`/categorias/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // Desactiva una categoría
  desactivarCategoria(id) {
    return apiClient.put(`/categorias/desactivar/${id}`);
  },
};
